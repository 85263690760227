import { DefaultTheme } from "styled-components";

const myTheme: DefaultTheme = {
    color: {
        violet: "#B299DC",
        lightViolet: "rgba(178, 152, 220, 0.3)",
        turquoise: "#B9FAF8",
        white: "#FFFFFF",
    },
    fontSize: {
        normal: "16px",
    },
    fontWeight: {
        thin: "400",
        normal: "500",
        bold: "600",
    },
};

export { myTheme };
